import React, { Component } from 'react';

import { restbaseurl } from '../../globals/constants';
import { RestDataSource } from '../../utils/RestDataSource';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import BottomAlert from '../../globals/uicomps/BottomAlert'

import { Route, Routes, Navigate  } from "react-router-dom";
import { LoginHeader } from '../../login/LoginHeader';
import LoginForm from '../../login/LoginForm';
import VerifyOTP from '../../login/VerifyOTP';
import UserInfo from '../../models/UserInfo';
import { setValue, getValue } from '../../globals/utils';
import { WithRouterHooks } from 'withFunctionHooks';

export class CandidateLoginLayout extends Component {

     constructor(props) {
          super(props);
          let index = this.props.location.pathname.lastIndexOf("/");
          let step = this.props.location.pathname.substr(index + 1).trim()
          console.log(index, step);
          this.state = {
               alertMsg: null,
               userData: null,
               forgotPassword: false,
               otpVerified: false,
               isSuspended: false,
               anotherWay: false,
               step: (step === '' || step === 'cdHome') ? 'login' : step,
               fullLoading: false
          };
          this.userid = UserInfo.getUserid()
          this.username = UserInfo.getUsername()
     }

     componentDidMount = async() => {
          document.body.classList.add('login-bg')
          const payloadData = await getValue();
          if (payloadData && payloadData['isloggedin'] && payloadData['roleCode'] === 'candidate') {
               if(payloadData['applicantId']){
                    document.location.href = "/candidate/bgvForm/entry";
               // }else if(this.userid){
               //      document.location.href = "/dashboard";
               }else{
                    //do nothing
               }
               
          }
          console.log("login layout mount")
     }


     validateLogin = (username, password) => {
          this.dataSource = new RestDataSource(`${restbaseurl}/login/applicantAuth`)
          this.setState({ loading: true });
          this.dataSource.GetData(
              async respData => {
                    if (respData.message === 'success') {
                         await setValue('uploadBy', respData.data.uploadBy);
                         await setValue('applicantId', respData.data.applicantId);
                         /*this.setState({ loading: false, userdata: respData.data, alertMsg: null, step: 'verifyOtp' },()=>{
                              this.props.navigate('verifyOtp');
                         });*/

                         this.setState({ loading: false }, () => this.loginSuccess())
                    }
                    else {
                         this.setState({ loading: false, alertMsg: respData.message });
                    }
               },
               { "username": username, "password": password },
               "POST"
          );
     }

     resendOTP = () => {
          this.checkResend = new RestDataSource(`${restbaseurl}/login/applicantResendOtp`)
          this.showAlert(null);
          this.setState({ loading: true }, async () => {
               const applicantId = await getValue('applicantId');
               this.checkResend.GetData(
                    async resendResponse => {
                         this.setState({ loading: false })
                         if (resendResponse.isBlocked) {
                              this.changeStepValue("blocked")
                         }
                         else {

                              await setValue('remainingTries', resendResponse.remainingTries)
                              console.log("otp-resent")
                         }
                    },
                    { 'applicantId': applicantId, "username": UserInfo.getUsername() },
                    "POST"
               );
          })
     }

     verifyOTP = (otp) => {

          this.verifyOTPReq = new RestDataSource(`${restbaseurl}/login/applicantOtpLogin`)
          this.showAlert(null);
          this.setState({ loading: true }, async () => {
               const applicantId = await getValue('applicantId');
               this.verifyOTPReq.GetData(
                    respData => {
                         this.setState({ loading: false })
                         if (respData.message === 'success') {
                              this.loginSuccess();
                         }
                         else {
                              if (respData.isBlocked === true) {
                                   this.changeStepValue("blocked")
                              }
                              else {
                                   this.showAlert(respData.message)
                              }

                         }
                    },
                    { 'applicantId': applicantId, "username": UserInfo.getUsername(), "otp": otp },
                    "POST"
               );
          })
     }

     loginSuccess = async () => {
          this.setState({ fullLoading: true });
          await setValue('isloggedin', true);
          document.body.classList.remove('login-bg');

          document.location.href = "/candidate/bgvForm/entry";

     }

     changeStepValue = (stepValue) => {
          console.log("stepValue:", stepValue);
          
          this.setState({ step: stepValue },()=>{
               this.props.navigate(stepValue);
          });
     }

     showAlert = (alertMsg) => {
          this.setState({ alertMsg: alertMsg });
     }

     render = () => {

          console.log("Navigate  Link Prop", this.props);
          console.log("Step", this.state.step);
          let path = this.props.match.path !== '/' ? this.props.match.path : '/home';
          return (

               <React.Fragment>
                              {this.state.fullLoading ? <LoadingSpinner /> :
                                   <React.Fragment>
                                        <LoginHeader />
                                        <div className="login-block">
                                             <div className="login-set">
                                                  {
                                                       this.state.loading === true ? <LoadingSpinner /> :
                                                            <React.Fragment>
                                                                 {
                                                                      (this.state.alertMsg) ? <BottomAlert status="danger" msg={this.state.alertMsg} /> : null
                                                                 }
                                                                 {
                                                                      <Routes>
                                                                           <Route path={`verifyOtp/*`} element={ <VerifyOTP {...this.props} match={`${path}/verifyOtp`} {...this.state.userdata} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} resendOTP={this.resendOTP} verifyOTP={this.verifyOTP} />} />
                                                                           <Route path={`login`} element={ <LoginForm {...this.props} hideForgotPassword={true} match={`${path}/login`} cbValidateLogin={this.validateLogin} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path="/" element={<Navigate to={this.state.step} />} />
                                                                      </Routes>
                                                                 }

                                                            </React.Fragment>
                                                  }
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                   </React.Fragment>
                              }
                               <img className="loginbg" src={'/images/login-bg.svg'} alt='' />
                         {/* <LoginFooter /> */}
                        
               </React.Fragment>
          );
     }
}
export default WithRouterHooks(CandidateLoginLayout);

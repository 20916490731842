import React, { Component } from 'react';
export class LoginHeader extends Component {

     render = () => {
          return (
               <div className="login-left">
                    <div className="mainlogo mb-5">
                         <img src={ '/images/logo.svg' } alt='metapath' />
                    </div>
                    <h5>Background Screening powered by</h5>
                    <h5>Agentic AI</h5>
                    <div className="clr20" ></div>
                    <div  >We take up the most difficult challenges and work our way through solving it, the easiest way possible.</div>
               </div>

          );
     }

}
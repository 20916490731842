import React, { PureComponent } from 'react';
/* global google */
import { withTranslation } from 'react-i18next';
import {processPlaceObject} from "../globals/utils"

class BTGoogleMap extends PureComponent {

     constructor(props) {
          super(props);
          this.state = {
               showMap: false,
               searchValue: ''
          }
          this.mapDiv = React.createRef();
          this.localityInput = React.createRef();
          this.localityData = {};
          this.canvasElement = ''
     }

     componentDidMount() {
          this.buildMap({ mapCenterPosition: { lat: 20.5937, lng: 78.9629 }, zoom: 5 });
          // this.setMarker({ mapCenterPosition: { lat: 20.5937, lng: 78.9629 }, isDraggable: false });
          navigator.geolocation.getCurrentPosition(this.geoLocationSuccessCallback, this.geoLocationFailureCallback);
     }

     buildMap = ({ mapCenterPosition, zoom }) => {
          this.map = new window.google.maps.Map(this.mapDiv.current, {
               center: mapCenterPosition,
               zoom: zoom ? zoom : 9,
               streetViewControl: false,
               mapTypeId: google.maps.MapTypeId.ROADMAP,
               mapTypeControlOptions: {
                    mapTypeIds: []
               }
          });
     }

     geoLocationSuccessCallback = (position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;

          let markerPosition = {
               lat: Number(latitude),
               lng: Number(longitude)
          };
          let markerOptions = {
               markerPosition: markerPosition,
               draggable: this.props.isDraggable ? this.props.isDraggable : false
          };
          this.map.setCenter(new window.google.maps.LatLng(latitude, longitude));
          this.map.setZoom(12);
          this.setMarker(markerOptions);
     }

     geoLocationFailureCallback = () => {
          // this.setState({ showMap: true });
          const indiaLocation = { lat: 20.5937, lng: 78.9629 };
          // this.buildMap({ ...indiaLocation, zoom: 5 });
          let markerOptions = {
               markerPosition: indiaLocation,
               draggable: this.props.isDraggable ? this.props.isDraggable : false
          };
          this.setMarker(markerOptions)
     }

     centerMapMarker = () => {
          this.updateViewAddress();
          google.maps.event.addListener(this.map, 'center_changed', () => {
               window.setTimeout(() => {
                    let center = this.map.getCenter();
                    this.marker.setPosition(center);

               }, 0);
               clearTimeout(this.viewAddressUpdateTimeout);
               document.getElementById('map-view-address').innerText = 'Locating ...';
               this.viewAddressUpdateTimeout = window.setTimeout(() => {
                    this.updateViewAddress();
               }, 800);
          });
     }

     updateViewAddress = () => {
          // eslint-disable-next-line new-parens
          let geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'location': this.marker.getPosition() }, (results, status) => {
               if (status === 'OK') {
                    // console.log('results-from-geocoder',document.getElementById('map-view-address'), results);
                    if (results[0]) {
                         if (document.getElementById('map-view-address')) {
                              document.getElementById('map-view-address').innerText = results[0].formatted_address;
                         }
                    } else {
                         if (document.getElementById('map-view-address')) {
                              document.getElementById('map-view-address').innerText = 'Unable to find address';
                         }
                    }
               } else {
                    window.alert('Geocoder failed due to: ' + status);
               }
          });
     }

     closeCallback = () => {
          this.props.onHide();
     }

     saveLocationCallback = (e) => {
          this.geocodeLatLng()
     }

     geocodeLatLng() {
          var markedLocation = document.getElementById('map-view-address').textContent;
          console.log(this.marker.getPosition, 'getPosition1')
          // eslint-disable-next-line new-parens
          let geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'location': this.marker.getPosition() }, (results, status) => {
               if (status === 'OK') {
                    console.log('results-from-geocoder', results);
                    if (results.length > 0) {
                         let processedAddressData = processPlaceObject(results);
                         this.props.onLocationSubmit(processedAddressData, this.canvasElement, markedLocation);
                         this.props.onHide();
                    } else {
                         window.alert('No results found');
                    }
               } else {
                    window.alert('Geocoder failed due to: ' + status);
               }
          });
     }

     setMarker = ({ markerPosition, isDraggable }) => {
          this.marker = new window.google.maps.Marker({
               position: markerPosition,
               animation: google.maps.Animation.DROP,
               draggable: isDraggable ? isDraggable : false,
               title: "Marked Location"
          });

          this.marker.setMap(this.map);
          this.addYourLocationButton(this.map, this.marker)
          this.centerMapMarker();
     }

     addYourLocationButton = (map, marker) => {
          var controlDiv = document.createElement('div');
          var myLocationWrap = document.createElement('div');
          myLocationWrap.classList.add('myLocationWrap');
          controlDiv.appendChild(myLocationWrap);
          var myLocationIcon = document.createElement('div');
          myLocationIcon.classList.add('myLocationIcon');
          myLocationIcon.id = 'you_location_img';
          myLocationWrap.appendChild(myLocationIcon);
          google.maps.event.addListener(map, 'dragend', function () {
               document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
          });
          myLocationWrap.addEventListener('click', function () {
               var imgX = '0';
               var animationInterval = setInterval(function () {
                    if (imgX === '-18') imgX = '0';
                    else imgX = '-18';
                    document.getElementById('you_location_img').style.backgroundPosition = imgX + 'px 0px';
               }, 500);
               if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                         var latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         marker.setPosition(latlng);
                         map.setCenter(latlng);
                         clearInterval(animationInterval);
                         document.getElementById('you_location_img').style.backgroundPosition = '-144px 0px';
                    });
               }
               else {
                    clearInterval(animationInterval);
                    document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
               }
          });
          controlDiv.index = 1;
          map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
     }

     initAutocomplete = () => {
          let map = this.map
          var input = document.getElementById('pac-input');
          this.setState({ searchValue: input.value })
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.addListener('bounds_changed', function () {
               searchBox.setBounds(map.getBounds());
          });
          searchBox.addListener('places_changed', function () {
               var places = searchBox.getPlaces();
               if (places.length === 0) {
                    return;
               }
               var bounds = new window.google.maps.LatLngBounds();
               places.forEach(function (place) {
                    if (!place.geometry) {
                         return;
                    }
                    if (place.geometry.viewport) {
                         bounds.union(place.geometry.viewport);
                    } else {
                         bounds.extend(place.geometry.location);
                    }
               });
               map.fitBounds(bounds);
          });
     }

     getCurrentLocation = () => {
          this.buildMap({ mapCenterPosition: { lat: 20.5937, lng: 78.9629 }, zoom: 5 });
          navigator.geolocation.getCurrentPosition(this.geoLocationSuccessCallback, this.geoLocationFailureCallback);
     }


     render = () => {
          const { t } = this.props;
          let { showInfo } = this.props;
          return (
               <React.Fragment>
                    <div className='sub-heading1 inline-block left'>{this.props.header ? this.props.header : 'Mark your location'}</div>
                    <div className='clr5'></div>
                    {showInfo ? <div className="sub-heading4">Note : If you are unable to locate your exact address in this map ,skip this and continue filling the form manually</div>
                         : null}
                    <div className="sub-heading2">{this.props.subheading ? this.props.subheading : ''}</div>
                    <div id='hold'></div>
                    <div className="form-group">
                         {/* <input type="text" className="form-control" placeholder="Enter locality" ref={this.localityInput} /> */}
                         <div id='googleMapWrapper' className='google_map_wrap'>
                              <div className="clr15"></div>
                              <div id='pac-container'>
                                   <input id="pac-input" className="controls map_inp_res" key='inputtag' type="text" placeholder="Type here to search" value={this.state.searchValue} onChange={this.initAutocomplete} />
                              </div>
                              <div id="you_location_img"></div>
                              <div id="map" ref={this.mapDiv}>
                              </div>
                              <div className="clr10"></div>
                              <div id="map-view-address"></div>
                         </div>
                    </div>
                    <div className="text-center">
                         <button type="button" className="btn btn_primary" onClick={this.saveLocationCallback}>{t('Confirm location')}</button>
                    </div>
               </React.Fragment >
          );
     }
}

export default withTranslation('translations')(BTGoogleMap);
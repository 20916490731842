import React, { Component } from 'react';
import VerificationStatusPanel from './VerificationStatusPanel';
import { Accordion } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import ConfirmationModal from '../globals/uicomps/ConfirmationModal';
import { LoadingSpinner } from '../utils/LoadingSpinner';

class VerificationTypeStatusPanel extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               subheading: '',
               showConfirmationModal: false
          }
          this.reComputeId = ''
     }
     reComputeCheck = (id) => {
          this.setState({
               showConfirmationModal: true,
               subheading: "Do you want to recompute this check",
          })
          this.reComputeId = id
     }

     confirmSuccessCallback = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/recomputeVerificationTypeDetailStatus`)
          this.setState({ loading: true }, () => {
               this.dataSource.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   loading: false
                              })
                              this.props.reRenderSidePanel()
                         }
                    },
                    { "id": this.reComputeId },
                    "POST"
               );
          });
     }

     render = () => {
          let json = {
               identity: "id",
               education: "education",
               employment: "suitcase",
               residence: "home",
               bankVerification: "court",
               court: "court",
               cibil: "credit",
               credit: "credit",
               drug: "drug",
               socialMedia: "share",
               reference: "check-reference",
               database: "database",
               cv: "cv",
               directorship: "directorship",
          };
          let value = this.props.value[this.props.data]
          let verificationList = value['verificationList']
          let status = value['status']
          let code = json[value['code']] ? json[value['code']] : 'id'
          let statuscode = (status === "Failed" ? 'close' : (status === "Insufficiency" ? 'warning' : status === "Clear" ? 'green-tick' : 'inprogress'))
          let colorClass = (status === "Failed" ? 'error-text' : (status === "Insufficiency" ? 'inprogress' : status === "Clear" ? 'completed' : 'primary-color'))
          // let show = ( this.props.active === true ) ?  'show' : ' '
          let elements = verificationList.map(data =>
               <VerificationStatusPanel index={this.props.index} value={data} showDeepTrack={this.props.showDeepTrack} hideDeepTrack={this.props.hideDeepTrack} key={data.name + Math.random()} completeState={this.props.completeState} caseId={this.props.caseId} enableEdit={this.props.enableEdit} />
          )
          const { t } = this.props;
          let { loading } = this.state;
          return (
               loading ? <LoadingSpinner /> :
                    <React.Fragment>
                         <div className="card  accordion" >
                              <Accordion.Item className="pack-accordion-head collapsed pointer" as="div" variant="link" eventKey={this.props.index} onClick={this.props.handle}>
                                   {/* <div className={`in-progress ${code} icon-status left`}></div> */}
                                   <img src={"/images/icons/icon-" + code + ".svg"} className="left" height={24} width={24} alt="icon" />
                                   <div className="left">
                                        <div className="sub-heading2">{t(value.name + " Check")}
                                             {this.props.enableEdit ? <img src={"/images/icons/icon-refresh.svg"} alt="icon" height={15} width={25} style={{ marginBottom: "2px" }} onClick={() => this.reComputeCheck(value.id)} /> : null}
                                        </div>
                                        <div className="clr5"></div>
                                        <div className="component-icons">
                                             <img src={"/images/component-icons/component-" + statuscode + ".svg"} alt="icon" />
                                             <span className={colorClass}>{status}</span>
                                        </div>
                                   </div>
                                   <img src={'/images/icons/icon-select-arrow.svg'} className={`right pointer ${this.props.active ? "open" : ""}`} height={24} width={24} alt="arrow" />
                                   <div className="clr"></div>
                              </Accordion.Item>
                              <Accordion.Collapse eventKey={this.props.index}>
                                   <div className="card-body">
                                        {this.props.active ? elements : null}
                                   </div>
                              </Accordion.Collapse>
                         </div >
                         <ConfirmationModal
                              show={this.state.showConfirmationModal}
                              heading="Are you sure ?"
                              subheading={this.state.subheading}
                              successButtonText="Yes"
                              failureButtonText="No"
                              successCallback={() => { this.confirmSuccessCallback() }}
                              failureCallback={() => { this.setState({ showConfirmationModal: false }) }}
                              closeCallback={() => { this.setState({ showConfirmationModal: false }) }}
                         ></ConfirmationModal></React.Fragment>
          );
     }
}
export default withTranslation('translations')(VerificationTypeStatusPanel);

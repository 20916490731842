import React, { Component } from 'react';

import { LoginHeader } from './LoginHeader';
import LoginForm from './LoginForm';
// import { LoginFooter } from './LoginFooter';
import { VerifyOTP } from './VerifyOTP';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import ForgotPassword from './ForgotPassword';
import BottomAlert from '../globals/uicomps/BottomAlert'
import ResetPassword from './ResetPassword';
import PhotoAuthenticator from "./PhotoAuthenticator"
import AccountSuspend from './AccountSuspend';
import TryAnotherWays from "./TryAnotherWays"
import Authenticator from './Authenticator';
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import md5 from 'md5';
import UserInfo from '../models/UserInfo';
import { getValue, setValue,setAllValue } from '../globals/utils';
import { encryptionKey } from '../globals/constants'
import * as ls from "local-storage";

export class LoginLayout extends Component {

     constructor(props) {
          super(props);
          let index = this.props.location.pathname.lastIndexOf("/");
          let step = this.props.location.pathname.substr(index + 1).trim()
          console.log("index:", index, step);
          this.state = {
               alertMsg: null,
               role: null,
               userData: null,
               forgotPassword: false,
               otpVerified: false,
               isSuspended: false,
               anotherWay: false,
               isAuthMFAEnabled: null,
               isAuthPhotoEnabled: null,
               step: (step === '' || step === 'home') ? 'login' : step,
               fullLoading: true,
               payloadData: null
          };
          this.userId = UserInfo.getUserid()
          this.username = UserInfo.getUsername();
          this.userName = '';
          this.updateLoginData()
     }

     componentDidMount = () => {
          document.body.classList.add('login-bg')
          this.redirectIfLoggedIn();
          console.log("login layout mount");
     }

     updateLoginData = () => {
          return new Promise((resolve) => {
               getValue().then((pData) => {
                    if (!pData) {
                         this.setState({
                              payloadData: null

                         })
                         this.changeStepValue("login");
                         resolve();
                    } else {
                         this.setState({
                              payloadData: pData,
                              role: pData['roleCode'],
                              isAuthMFAEnabled: pData['authenticatorEnabled'],
                              isAuthPhotoEnabled: pData['faceIdEnabled']
                         }, () => {
                              resolve();
                         });
                    }
               })
          })

     }

     redirectIfLoggedIn = () => {
          this.updateLoginData().then(() => {
               console.log("this.state.payloadData:", this.state.payloadData);
               if (this.state.payloadData && this.state.payloadData['isloggedin']) {
                    const role = this.state.payloadData['roleCode'];
                    if (role === 'vendor') {
                         document.location.href = "/vendorDashboard";
                    } else if (role === 'operations') {
                         document.location.href = "/operations";
                    } else if (role === 'de') {
                         document.location.href = "/dataEntry";
                    } else if (role === 'reportviewer') {
                         document.location.href = "/reportViewerDashboard";
                    } else if (role === 'documentviewer') {
                         document.location.href = "/documentViewerDashboard";
                    } else if (role === 'qc') {
                         document.location.href = "/qcDashboard";
                    } else {
                         if (this.state.payloadData['firstTimeLogin'] === true) {
                              document.location.href = "/onboarding";
                         }
                         else if (this.state.payloadData['rolePriority'] <= this.state.payloadData['roleMap']['chp']) {
                              if (this.state.payloadData['rolePriority'] !== '' && this.state.payloadData['rolePriority'] !== undefined && (this.state.payloadData['rolePath'] !== '')) {
                                   document.location.href = `/${this.state.payloadData['rolePath']}`;
                              } else if (this.state.payloadData['rolePriorityList'] && this.state.payloadData['rolePriorityList'] !== undefined && this.state.payloadData['rolePriorityList'].length >= 2) {
                                   document.location.href = '/chooseRole';
                              } else {
                                   document.location.href = "/adminDashboard";
                              }
                         }
                         else {
                              document.location.href = "/dashboard";
                         }
                    }
               }else{
                    this.setState({"fullLoading":false})
               }
          })

     }

     validateLogin = (username, password) => {
          password = password.trim()
          this.dataSource = new RestDataSource(`${restbaseurl}/login/auth`)
          this.setState({ loading: true });
          this.dataSource.GetData(
               respData => {
                    if (respData.message === 'success') {
                         (respData.data['authenticatorEnabled'] || respData.data['faceIdEnabled']) ?
                              this.setState({ loading: false, userdata: respData.data, step: 'tryAnotherWays', alertMsg: null })
                              //: this.setState({ loading: false, userdata: respData.data, step: 'verifyOtp', alertMsg: null }, () => this.triggerOtp())
                              : this.setState({ loading: false }, () => this.loginSuccess())
                    }
                    else if (respData.isBlocked === true) {
                         this.setState({ loading: false, step: 'blocked' })
                    }
                    else {
                         this.setState({ loading: false, alertMsg: respData.message });
                    }
               },
               { "username": username, "password": md5(password) },
               "POST"
          );
     }

     validateDetails = (userName, mobileNumber) => {
          this.checkDetails = new RestDataSource(`${restbaseurl}/login/forgotPassword`)
          this.setState({ loading: true });
          this.checkDetails.GetData(
               async (respData) => {
                    if (respData.message === 'success') {
                         let token = await setAllValue({
                              'isloggedin': false,
                              'isForgotPassword': true,
                              'username': respData.data.username,
                              'userId': respData.data.userId,
                              'clientId': respData.data.clientId,
                              'firstTimeLogin': respData.data.firstTimeLogin,
                              'loginname': respData.data.firstname,
                              'clientEmail': respData.data.clientEmail,
                              'otpExpiryTime': respData.data.otpExpiryTime,
                              'mobilenumber': respData.data.mobilenumber,
                              'authenticatorEnabled': respData.data.authenticatorEnabled,
                              'faceIdEnabled': false,
                              'rolePriority': '',
                              'rolePath': ''
                         });
                         this.setState({ loading: false, step: 'verifyOtp', isAuthPhotoEnabled: false });
                    }
                    else {
                         this.setState({ loading: false });
                         this.showAlert(respData.message)
                    }
               },
               { "userName": userName, "mobileNumber": mobileNumber },
               "POST"
          );
     }

     resendOTP = () => {
          this.checkResend = new RestDataSource(`${restbaseurl}/login/resendOtp`)
          this.showAlert(null);
          this.setState({ loading: true }, () => {
               this.checkResend.GetData(
                    async resendResponse => {
                         this.setState({ loading: false })
                         if (resendResponse.isBlocked) {
                              this.changeStepValue("blocked")
                         }
                         else {
                              await setValue('remainingTries', resendResponse.remainingTries)
                         }
                    },
                    { 'userId': UserInfo.getUserid(), "username": UserInfo.getUsername() },
                    "POST"
               );
          })
     }

     triggerOtp = () => {
          this.otpRequest = new RestDataSource(`${restbaseurl}/login/triggerOtp`);
          this.setState({ loading: true });
          this.otpRequest.GetData(
               async respData => {
                    if (respData.status === 'success') {
                         if (respData.isBlocked) {
                              this.changeStepValue("blocked");
                         } else {
                              await setValue('remainingTries', respData.remainingTries);
                         }
                    } else {
                         this.showAlert(respData.message)
                    }
                    this.setState({ loading: false });
               },
               { "userId": UserInfo.getUserid() },
               "POST"
          );
     }

     verifyOTP = (otp) => {
          this.updateLoginData().then(()=>{
               this.verifyOTPReq = new RestDataSource(`${restbaseurl}/login/otpLogin`)
               this.showAlert(null);
               this.setState({ loading: true }, () => {
                    this.verifyOTPReq.GetData(
                         respData => {
                              if (respData.message === 'success') {
                                   if (this.state.payloadData['isForgotPassword']) {
                                        this.changeStepValue("resetPassword")
                                        this.setState({ loading: false })
                                   }
                                   else {
                                        this.loginSuccess();
                                   }
                              }
                              else {
                                   if (respData.isBlocked === true) {
                                        this.changeStepValue("blocked")
                                   }
                                   else {
                                        console.log("alertMsg", respData.message)
                                        this.showAlert(respData.message)
                                   }
                                   this.setState({ loading: false })
                              }
                         },
                         { 'userId': UserInfo.getUserid() ? UserInfo.getUserid() : this.state.payloadData['userId'], "username": UserInfo.getUsername() ? UserInfo.getUsername() : this.state.payloadData['username'], "otp": otp },
                         "POST"
                    );
               })
          })
          
     }

     loginSuccess = () => {
          setValue('isloggedin', true).then(()=>{
               this.setState({ fullLoading: true });
               document.body.classList.remove('login-bg');
               this.redirectIfLoggedIn();
          });
          
     }

     changeStepValue = (stepValue, userName) => {
          if (stepValue === 'verifyOtp') {
               this.triggerOtp();
          }
          this.userName = userName;
          this.setState({ step: stepValue });
     }

     showAlert = (alertMsg) => {
          this.setState({ alertMsg: alertMsg });
     }

     render = () => {
          if (ls.get('token') === "" || ls.get('token') === undefined) {
               this.redirectIfLoggedIn();
          }
          let path = '/home';//this.props.match.pathname !== '/' ? '/home' : '/home';

          return (

               <React.Fragment>

                    {/* <Router> */}
                    <GoogleReCaptchaProvider reCaptchaKey="6Lf9YE0kAAAAAPv4zL_0IqT6KSzTX7P6AMTFvd1D">
                         {this.state.fullLoading ? <LoadingSpinner /> :
                              <React.Fragment>
                                   <LoginHeader />
                                   <div className="login-block">
                                        <div className="login-set">
                                             {
                                                  this.state.loading === true ? <LoadingSpinner /> :
                                                       <React.Fragment>
                                                            {
                                                                 (this.state.alertMsg) ? <BottomAlert status="danger" msg={this.state.alertMsg} /> : null
                                                            }
                                                            {
                                                                 <Routes>
                                                                      <Route path={`${path}/verifyOtp`} element={<VerifyOTP {...this.props} {...this.state.userdata} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} resendOTP={this.resendOTP} verifyOTP={this.verifyOTP} />} />
                                                                      <Route path={`${path}/tryAnotherWays`} element={<TryAnotherWays {...this.props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                      <Route path={`${path}/photoAuthenticator`} element={<PhotoAuthenticator {...this.props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                      <Route path={`${path}/authenticator`} element={<Authenticator {...this.props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} loginSuccess={this.loginSuccess} />} />
                                                                      <Route path={`${path}/forgotPassword`} element={<ForgotPassword {...this.props} userName={this.userName} forwardToOtp={this.validateDetails} changeStepValue={this.changeStepValue} />} />
                                                                      <Route path={`${path}/resetPassword`} element={<ResetPassword {...this.props} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} />} />
                                                                      <Route path={`${path}/blocked`} element={<AccountSuspend {...this.props} changeStepValue={this.changeStepValue} />} />
                                                                      <Route path={`${path}/login`} element={<LoginForm {...this.props} cbValidateLogin={this.validateLogin} changeStepValue={this.changeStepValue} />} />

                                                                 </Routes>
                                                            }

                                                       </React.Fragment>
                                             }
                                        </div>
                                        <div className="clr"></div>
                                   </div>
                              </React.Fragment>
                              /* <LoginFooter /> */
                         }
                         <img className="loginbg" src={'/images/login-bg.svg'} alt='' />
                    </GoogleReCaptchaProvider>


                    {<Navigate to={`${path}/${this.state.step}`} replace />}
                    {<Outlet />}
               </React.Fragment>
          );
     }
}

//
/*



     */



export const trans_en = {
     translations: {
          "Welcome to React": "Welcome to React and react-i18next",
          username: "Username",
          password: "Password",
          Invite: "Invite",
          "Enter Details Now": "Enter Details Now",
          "Who uploads the candidate documents ?":
               "Who uploads the candidate documents ?",
          "Drop file here or click to browse": "Drop file here or click to browse",
          "Candidate Details": "Candidate Details",
          "IMPORT FROM XLS": "IMPORT FROM XLS",
          Name: "Name",
          Email: "Email",
          "Mobile Number": "Mobile Number",
          Entity: "Entity",
          Pack: "Pack",
          "INITIATE NEW CASE": "INITIATE NEW CASE",
          "Documents identified": "Documents identified",
          "Unidentifiable documents": "Unidentifiable documents",
          "Initiated cases": "Initiated cases",
          "VIEW RESPONSES": "VIEW RESPONSES",
          "Upload by": "Upload by",
          Total: "Total",
          Responses: "Responses",
          Pending: "Pending",
          Inprogress: "Inprogress",
          Alert: "Alert",
          Completed: "Completed",
          "NEW CASE": "NEW CASE",
          "Get started by creating a new case": "Get started by creating a new case",
          Processing: "Processing",
          "Download Report": "Download Report",
          SUBMIT: "SUBMIT",
          CANCEL: "CANCEL",
          Candidate: "Candidate",
          "Document type": "Document type",
          "Tag document": "Tag document",
          "Add document": "Add document",
          "Select one": "Select one",
          "Received Responses": "Received Responses",
          All: "All",
          "Response Time": "Response Time",
          Myself: "Myself",
          "SEND INVITES": "SEND INVITES",
          Logout: "Logout",
          "User management": "User management",
          "Permission Group": "Permission Group",
          Notifications: "Notifications",
          "Profile settings": "Profile settings",
          "Company Settings": "Company Settings",
          "administration.notificationPanel.markAllAsRead.label": "MARK ALL AS READ",
          More: "More",
          "view completed reports": "view completed reports",
          New: "New",
          "Profile Settings": "Profile Settings",
          Change: "Change",
          "First Name": "First Name",
          "Last Name": "Last Name",
          Mobile: "Mobile",
          UPDATE: "UPDATE",
          "Company Logo": "Company Logo",
          Website: "Website",
          Details: "Details",
          Cases: "Cases",
          "Creation time": "Creation time",
          Status: "Status",
          Sortby: "Sort by : Relevance",
          "Select All": "Select All",
          TAT: "TAT",
          "Mark as unread": "Mark as unread",
          SearchCandidates: "Search Candidates",
          "Clear All": "Clear All",
          "Aadhar Card": "Aadhar Card",
          Passport: "Passport",
          "Pan Card": "Pan Card",
          "Driving Licence": "Driving Licence",
          "Voter Id": "Voter Id",
          "10th Onwards": "10th Onwards",
          "12th Onwards": "12th Onwards",
          UG: "UG",
          "Highest Education": "Highest Education",
          "Latest Employments": "Latest Employments",
          "Employments in past": "Employments in past",
          "All Previous Employments": "All Previous Employments",
          "Panel 4": "Panel 4",
          "Panel 5": "Panel 5",
          "Panel 6": "Panel 6",
          "Panel 7": "Panel 7",
          "Panel 8": "Panel 8",
          "Panel 9": "Panel 9",
          "Panel 10": "Panel 10",
          "Panel 11": "Panel 11",
          "Panel 12": "Panel 12",
          "Current Address": "Current Address",
          "Permanent Address": "Permanent Address",
          "Past N Years": "Past N Years",
          Current: "Current",
          Permanent: "Permanent",
          Past: "Past",
          "Credit Check": "Credit Check",
          "Database Check": "Database Check",
          "Social Check": "Social Check",
          "metapath Insights": "metapath Insights",
          "Verification Status": "Verification Status",
          "Identity Check": "Identity Check",
          "Employment Check": "Employment Check",
          "Education Check": "Education Check",
          "Report will be ready in": "Report will be ready in",
          days: "days",
          "Verify OTP to continue": "Verify OTP to continue",
          "Enter the OTP sent to": "Enter the OTP sent to",
          "Wrong OTP. Please try again": "Wrong OTP. Please try again",
          "VERIFY & PROCEED": "VERIFY & PROCEED",
          "Number of references": "Number of references",
          CurrentPassword: "Current Password",
          NewPassword: "New Password",
          ReenternewPassword: "Re enter new Password",
          Skip: "Skip",
          "Change your Password": "Change your Password",
          "You can also customize this details in the settings":
               "You can also customize this details in the settings",
          "Enter your details": "Enter your details",
          "Company Details": "Company Details",
          back: "back",
          submit: "submit",
          Next: "Next",
          "Entity Name": "Entity Name",
          "bankAccountNumber": "Bank Account Number",
          "ifscCode": "IFSC Code",
          "itrUserName": "ITR User Name",
          "itrPassword": "ITR Password",
          Code: "Code",
          "GST Number": "GST Number",
          "Door / Flat No": "Door / Flat No",
          "Address line 1": "Address line 1",
          "Pin code": "Pin code",
          City: "City",
          Country: "Country",
          User: "User",
          add: "add",
          "Country code": "Country code",
          "Create New USER": "Create New USER",
          Packs: "Packs",
          "Mobile number": "Mobile number",
          "Basic pack": "Basic pack",
          Default: "Default",
          "Full pack": "Full pack",
          "Create a new Entity": "Create a new Entity",
          "Create a custom pack": "Create a custom pack",
          "CREATE NEW": "CREATE NEW",
          save: "save",
          cancel: "cancel",
          Roles: "Roles",
          Policy: "Policy",
          CREATE: "CREATE",
          "Amphisoft Technologies": "Amphisoft Technologies",
          "1200 / case)": "1200 / case)",
          "Full pack)": "Full pack)",
          "1500 / case": "1500 / case",
          INR: "INR",
          Entities: "Entities",
          "CREATE A NEW ENTITY": "CREATE A NEW ENTITY",
          "Needs approval for case creation": "Needs approval for case creation",
          "SocialIntelligenceHeader": "Social Intelligence Searching",
          "SocialIntelligenceDesc": "AI built profile for every individual",
          "SocialIntelligenceSearchPlaceholder": "Search here",
          "SocialIntelligenceExampleText": "For example: foo@bar.com, @joemontana, +1 1234567890",
          "SocialIntelligenceUserPageBackButton": "GO BACK",
          "SocialIntelligenceUserPageLocation": "Location",
          "SocialIntelligenceUserPageDevicesOwned": "Devices Owned",
          "SocialIntelligenceUserPageLanguages": "Languages",
          "SocialIntelligenceUserPagePersonality": "Personality",
          "SocialIntelligenceUserPageExtraversion": "Extraversion",
          "SocialIntelligenceUserPageAgreeableness": "Agreeableness",
          "SocialIntelligenceUserPageEmotionalStability": "Emotional Stability",
          "SocialIntelligenceUserPageConscientiousness": "Conscientiousness",
          "SocialIntelligenceUserPagePersona": "Persona",
          "SocialIntelligenceDiscChartHeader": "DISC Assessment",
          "MoodChartHeader": "Mood",
          "MoodChartSentiment": "Sentiment",
          "MoodChartDiscChartHeader": "DISC Assessment",
          "firstName": "First Name",
          "email": "Email",
          "mobileNumber": "Mobile Number",
          "entity": "Entity",
          "pack": "Pack",
          "uploadDocuments": 'Upload documents',
          "unidentifiableDocuments": 'Unidentifiable documents',
          "requiredDocuments": 'Required documents',
          "prev": 'Prev',
          "next": 'Next',
          "last 7 days": "last 7 days",
          "last 15 days": "last 15 days",
          "last 30 days": "last 30 days",
          "last 6 months": "last 6 months",
          "Date range": "Date range",
          "Background Checks": "Background Checks",
          "Cleared Results": "Cleared Results",
          "VIEW cleared results": "VIEW cleared results",
          "Results with Alerts": "Results with Alerts",
          "VIEW result alerts": "VIEW result alerts",
          "Insufficiencies": "Insufficiencies",
          "VIEW INSUFFICIENT CASES": "VIEW INSUFFICIENT CASES",
          "In Progress": "In Progress",
          "VIEW in progress": "VIEW in progress",
          "Approvals": "Approvals",
          "VIEW approvals": "VIEW approvals",
          "Batch": "Batch",
          "On time": "On time",
          "Overdue": "Overdue",
          "Back to Login": "Back to Login",
          "Forgot password ?": "Forgot password ?",
          "New password": "New password",
          "Re enter new password": "Re enter new password",
          "AuthenticateText": "Your account is secured with multi-factor authentication ( MFA ). To finish signing in, turn on or view your MFA device and type the authentication code below.",
          "Invite failed": "Invite failed",
          "Invites sent successfully": "Invites sent successfully",
          "Forgot_Password": "Forgot Password",
          "Deny": "Deny",
          "APPROVE": "APPROVE",
          "No pending Approvals": "No pending Approvals",
          "Yet to start": "Yet to start",
          "Showing case summary from": "Showing case summary from",
          "Documents": "Documents",
          aadhar_number: "Aadhar Number",
          aadhar_name: "Name",
          aadhar_fathername: "Father Name",
          aadhar_gender: "Gender",
          aadhar_dob: "Date of birth",
          aadhar_address: "Address",

          passport_number: "Passport Number",
          passport_name: "Name",
          passport_countryCode: "Country Code",
          passport_surname: "Surname",
          passport_gender: "Gender",
          passport_dob: "Date of birth",
          passport_nationality: "Nationality",
          passport_placeOfBirth: "Place of Birth",
          passport_placeOfIssue: "Place of Issue",
          passport_doi: "Date of Issue",
          passport_doe: "Date of Expiry",
          passport_fatherName: "Father Name",
          passport_motherName: "Mother Name",
          passport_address: "Address",

          pan_number: "Pan Number",
          pan_name: "Name",
          pan_dob: "Date of birth",

          Cancel: "Cancel",
          Select: "Select",
          Male: "Male",
          Female: "Female",
          licence_number: "Licence Number",
          name: "Name",
          licence_sdw: "Son/daughter/Wife Name",
          licence_dob: "Date of birth",
          licence_doi: "Date of Issue",

          degree: "Degree",
          rollNumber: "Roll number",
          instituteName: "Institute name",
          year: "Year",
          score: "Score",
          universityName: "University name",
          programmeAndBranch: "Programme and Branch name",
          company_name: "Company Name",
          address: "Address",
          from_date: "From Date",
          to_date: "To Date",
          content: "Content",
          Document_type: "Document Type",
          "Suggested Verification date": "Suggested Verification date",
          "Board": "Board",
          "Marks": "Marks",
          "Registration Number": "Registration Number",
          "Institution": "Institution",
          "Reference Type": "Reference Type",
          "Case Initiated successfully": "Case Initiated successfully",
          "Preview": "Preview",
          "Use marked location": "Use marked location",
          "Entity / Batch wise statistics": "Entity / Batch wise statistics",
          "The Journey So Far": "The Journey So Far",
          send_link: "send link",
          Reset_Message: "A password reset link will be sent to the registered email id ",
          SearchBox: "SearchBox",
          CreatedBy: "CreatedBy",
          disable_account: "This user will be permanently deleted",
          confirm: "Confirm",
          "Password Reset Successfully": "Password Reset Successfully",
          success: "success",
          change_tat_to_7: "Change TAT from 14 days to 7 days",
          change_tat_to_14: "Change TAT from 7 days to 14 days",
          send_Request: "SEND REQUEST",
          change_tat_msg: "A request for turn around time change will be sent to contact@metapath.ai ( Client Happiness Partner ).",
          send_Request_msg: "Request send successfully",
          LOA: "Letter of Authorization",
          valid_from: "Valid from",
          user: "USER",
          create_new_user: "CREATE NEW USER",
          are_you_sure: "Are you sure ?",
          remove_user_alert: "Do you want remove this User from this entity ?",
          // remove_user_alert_spoc:"The user you are trying to remove is a SPOC , so if you remove the SPOC then the Parent entity SPOC will be allocated to this entity",
          remove_user_alert_spoc: "You are trying to remove a spoc for this entity , this action will make the parent entity spoc as spoc to this entity",
          recheck_alert: "Do you want to recheck ?",
          add_caps: "ADD",
          create_new_entity: "CREATE NEW ENTITY",
          spoc: "spoc",
          no_user_assigned: "No User Assigned",
          approval_required: "Approval Required",
          Organization: "Organization",
          bgvformnew_employmentcontainer_isFresher: "Are you a fresher?",
          bgvformnew_employmentcontainer_currency: "Currency",
          bgvformnew_employmentcontainer_worklocation: "Work Location",
          bgvformnew_employmentcontainer_ssn: "Social Security Number",
          bgvformnew_employmentcontainer_orgnizationcontactnumber: "Organization Contact Number",
          add_Company: "Add Company",
          add_Address: "Add Address",
          Manager_Designation: "Manager Designation",
          company_Name: "Company Name",
          Address: "Address",
          Pincode: "Pincode",
          State: "State",
          contact_Number: "Contact Number",
          'last 3 years': 'last 3 years',
          'Case Number': 'Case Number',
          add_Institution: "Add Institution",
          institution_Name: "Institution Name",
          'uploadArea.label.default': 'Drop file here or click to browse',
          'adminlayout.tabs.casemanagement.label': 'Case Management',
          'adminlayout.tabs.casemanagement.search.placeholder': 'Case Number / Name',
          'Experience': 'Experience',
          'adminlayout.tabs.casemanagement.casecard.reportreset.title': 'Reset PDF Report',
          'adminlayout.tabs.organizationmanagement.search.placeholder': 'Search by name',
          "doctor_reg_id": "Doctor Registration No",
          "remarks": "Remarks",
          "finalDisposition": "Final Disposition",
          "yearOfPass": "Year Of Pass",
          "firstname": "Firstname",
          "middlename": "middlename",
          "lastname": "Lastname",
          "smcName": "Smc Name",
          "reg_Date": "Registration Date",
          "startDate": "Start Date",
          "endDate": "End Date",
          "description": "Description",
          surName: "SurName",
          dob: "Date of birth",
          doe: "Date of expiry",
          doi: "Date of Issue",
          number: "Number",
          countryCode: "Country Code",
          gender: "Gender",
          toAddress: 'Recepient',
          cc: 'CC',
          subject: 'Subject',
          comments: 'Comments',
          cost: 'Cost',
          casenumber: 'Case Number',
          "adminlayout.tabs.scriptinit.label": "SV Tool Case Creation",
          "din": "DIN",
          "companyname": "Company Name",
          "fathername": "Father's Name",
          "employment.uanDetailsTable.table.header.serialNumber": "S.No.",
          "employment.uanDetailsTable.table.header.uan": "UAN",
          "employment.uanDetailsTable.table.header.memberId": "Member Id",
          "employment.uanDetailsTable.table.header.establishmentId": "Establishment Id",
          "employment.uanDetailsTable.table.header.establishmentName": "Establishment Name",
          "employment.uanDetailsTable.table.header.dateOfJoining": "Date of Joining",
          "employment.uanDetailsTable.table.header.dateOfExit": "Date of Exit",
          "casesDashboard.filters.caseStatus": "Case Status",
          "documentViewer.documentSearch.search.input.placeholder": "Enter Name or Case number",
          "documentViewer.documentSearch.heading": "Source Documents",
          "documentViewer.documentSearch.search.button": "Get Documents",
          "documentViewer.documentSearch.result.noDocumentAvailable": "No documents available",
          "documentViewer.documentSearch.result.downloadZip": "Download All",
          "documentViewer.documentSearch.result.basicInfo.name": "Name",
          "documentViewer.documentSearch.result.basicInfo.dateOfBirth": "Date of Birth",
          "adminlayout.tabs.notification.label": "Notifications",
          "month.january": "January",
          "month.february": "February",
          "month.march": "March",
          "month.april": "April",
          "month.may": "May",
          "month.june": "June",
          "month.july": "July",
          "month.august": "August",
          "month.september": "September",
          "month.october": "October",
          "month.november": "November",
          "month.december": "December",
          "report.misdashboard.weeklyreport.week1": "Week 1",
          "report.misdashboard.weeklyreport.week2": "Week 2",
          "report.misdashboard.weeklyreport.week3": "Week 3",
          "report.misdashboard.weeklyreport.week4": "Week 4",
          "report.misdashboard.weeklyreport.week5": "Week 5",
          "report.misdashboard.weeklyreport.week6": "Week 6",
          "addressLineOne": "Address Line",
          "city": "City",
          "state": "State",
          "pincode": "Pincode",
          "country": "Country",
          "documentViewer.caseSearch.search.button": "Search",
          "reInitiationDate": "Re Initiated Date",
          "dueDate": "Due Date",
          "interimReportDate": "Interim Report Date",
          "insuffRemarks": "Insuff Remarks",
          "noOfInsuff": "No.of Insuff",
          "typeOfInsuff": "Type Of Insuff",
          "ceInitiatedDate": "CE Initiated Date",
          "insuffRaisedDate": "Insuff Raised Date",
          "lastName": "Last Name",
          "fatherName": "Father Name",
          "motherName": "Mother Name",
          "residenceLocation": "Residence Location",
          "costaricaIdNumber": "Costa Rica Id Number",
          "mobile": "Mobile Number",
          "reportTat": "TAT (in mins)",
          "employeeId": "Employee Id",
          "externalBgvId": "Applicant Id (Bgv Id)",
          "packName": "Pack Name",
          "completedDate": "Completed Date",
          "dateCreated": "Case Created Date",
          "insuffClearedDate": "Insuff Cleared Date",
          "reportColor": "Report Color",
          "interimReportColor": "Interim Report Color",
          "interimReportStatus": "Interim Report Status",
          "caseStatus": "Case Status",
          "completed": "Completed",
          "sourceOfVerification": "Source Of Verification",
          "modeOfVerification": "Mode Of Verification",
          "placeOfBirth": "Place Of Birth",
          "dateOfBirth": "Date of Birth",
          "dateOfIssue": "Date of Issue",
          "dateOfExpiry": "Date of Expiry",
          "placeOfIssue": "Place of Issue",
          "nationality": "Nationality",
          "documentString": "Document String",
          "programme": "Programme",
          "accreditedBy": "Accredited By",
          "passoutYear": "Passout Year",
          "isEduInsSuspicious": "University found in vendor suspicious list (Yes / No)",
          "education.comment": "Final Disposition",
          "reasonForSuspiciousResearch": "Reason For Suspicious",
          "researchAreas": "Research Areas",
          "checkedSuspicious": "Checked Suspicious (Yes / No)",
          "rocConducted": "Roc Conducted (Yes / No)",
          "onlineSearch": "Online Search (Yes / No)",
          "domainSearch": "Domain Search (Yes / No)",
          "siteVisited": "Site Visited (Yes / No)",
          "providentFundSearch": "Provident Fund Search (Yes / No)",
          "tanSearch": "Tan Search (Yes / No)",
          "organizationName": "Organization",
          "registration": "ROC Registration (Yes / No)",
          "suspiciousList": "Suspicious List (Yes / No)",
          "designation": "Designation",
          "designationComments": "Designation Comments",
          "supervisor": "Supervisor",
          "reportingManagerComments": "Supervisor Comments",
          "supervisorDesignation": "Supervisor Designation",
          "reasonForLeaving": "Reason For Leaving",
          "reasonForLeavingComments": "Reason For Leaving Comments",
          "salary": "Remunerations",
          "salaryComments": "Remunerations Comments",
          "exitFormalitiesComments": "Exit Formalities Comments",
          "eligibleToRehireComments": "Eligible To Rehire Comments",
          "finalSettlementComments": "Final Settlement Comments",
          "tanNumber": "Tan Number",
          "panNumber": "Pan Number",
          "empFromPeriod": "Emp From",
          "empFromPeriodComments": "Emp From Comments",
          "empToPeriod": "Emp To",
          "empToPeriodComments": "Emp To Comments",
          "verifierName": "Verifier Name",
          "verifierDesignation": "Verifier Designation",
          "verificationTime": "Date & Time",
          "bankName": "Bank Name",
          "bankAccountNo": "bankAccountNo",
          "period": "Bank Period",
          "verifierComments": "Verifier Comments",
          "tenureFrom": "Tenure From",
          "tenureTo": "Tenure To",
          "respondentName": "Respondent Name",
          "respondentPhoneNumber": "Respondent PhoneNumber",
          "addressType": "Address Type",
          "revertType": "Revert Type",
          "stayStartDate": "Stay From",
          "stayEndDate": "Stay To",
          "periodOfStayFrom": "Information Verified - Stay From",
          "periodOfStayTo": "Information Verified - Stay To",
          "dateOfVisit": "Verified Date",
          "relationship": "Relationship",
          "aboutEducation": "Edu Background",
          "aboutEmployment": "Emp Background",
          "professionalStrength": "Strength",
          "professionalWeakness": "Weakness",
          "attitude": "Attitude",
          "integrity": "Integrity",
          "stability": "Stability",
          "knownSince": "Known Since",
          "callTime": "Call Time",
          "verifiedBy": "Verified By",
          "checkType": "Drug Type",
          "givenName": "",
          "bloodgroup": "Blood Group",
          "reportVerifiedDate": "Report Verified Date",
          "educationGapCorrectness": "Educaion Gap Correctness (Yes / No)",
          "employmentGapCorrectness": "Employment Gap Correctness (Yes / No)",
          "educationCorrectness": "Education Correctness (Yes / No)",
          "employmentCorrectness": "Employment Correctness (Yes / No)",
          "gapPeriod": "Gap Period",
          "gapComments": "Gap Remarks",
          "gapFinalDisposition": "Gap Final Disposition",
          "employmentComments": "Cv Remarks",
          "gapStatus": "Gap Status",
          "cvStatus": "Cv Status",
          "cvFinalDisposition": "Cv Final Disposition",
          "verifiedDate": "Verified Date",
          "interimTat": "Interim Tat",
          "finalTat": "Final Tat",
          "ceTat": "CE Tat",
          "locality": "Locality",
          "includeReportShared": "Include Report Shared",
          "topPriority": "Top Priority",
          "TAT (days)": "TAT (days)",
          "District": "District",
          "instructions": "Instructions",
          "courtProgressTable.header.sno": "S.No.",
          "courtProgressTable.header.year": "Year",
          "courtProgressTable.header.courtComplex": "Court Complex",
          "courtProgressTable.header.completed": "Completed",
          "yes": "Yes",
          "no": "No",
          "goBack": "Go Back",
          "mailerTemplate": "Mailer Template",
          "mailerTemplate.table.name": "Name",
          "mailerTemplate.table.purpose": "Purpose",
          "mailerTemplate.table.client": "Client",
          "mailerTemplate.button.new": "NEW",
          "documentVerifierTable.filters.showCollectedCases": "Show Collected Cases",
          "documentVerifierTable.header.sno": "S.No.",
          "documentVerifierTable.header.bgvId": "Bgv Id",
          "documentVerifierTable.header.caseNumber": "Case Number",
          "documentVerifierTable.header.candidateName": "Candidate Name",
          "documentVerifierTable.header.caseType": "Case Type",
          "documentVerifierTable.header.email": "Email",
          "documentVerifierTable.header.mobileNumber": "Mobile",
          "documentVerifierTable.header.status": "Status",
          "documentVerifierTable.header.action": "Action",
          "documentVerifierTable.header.clientName": "Client Name",
          "documentVerifierTable.header.tat": "TAT",
          "documentVerifierRow.action.options.sourceDocuments": "Source Documents",
          "documentVerifierRow.action.options.comments": "Comments",
          "documentVerifierRow.action.options.packInfo": "Pack Info",
          "documentVerifierRow.action.options.upload": "Upload",
          "operations.court.listview.table.header.sno": "S. No.",
          "operations.court.listview.table.header.caseNumber": "Case Number",
          "operations.court.listview.table.header.name": "Candidate Name",
          "operations.court.listview.table.header.client": "Client",
          "operations.court.listview.table.header.address": "Address",
          "operations.court.listview.table.header.tat": "TAT(days)",
          "operations.court.listview.table.header.status": "Status",
          "operations.court.listview.table.header.assignedTo": "Assigned To",
          "operations.court.listview.table.header.action": "Action",
          "reports.operationsTatSummary.table.header.check": "Check",
          "reports.operationsTatSummary.table.header.internalTat": "Internal TAT(days)",
          "reports.operationsTatSummary.table.header.casesWorkedToday": "Cases Worked Today",
          "reports.operationsTatSummary.table.header.totalCases": "Total Cases",
          "reports.operationsTatSummary.table.header.withinTat": "Within TAT",
          "reports.operationsTatSummary.table.header.beyondTat": "Beyond TAT",
          "reports.operationsTatSummary.table.header.beyondTenDays": ">10 days",
          "reports.operationsTatSummary.table.header.withinTatPercentage": "Within TAT %",
          "qcAllocation.yetToAssignList.heading": "Yet to Assign List",
          "qcAllocation.yetToAssignList.table.header.sno": "S.No.",
          "qcAllocation.yetToAssignList.table.header.caseNumber": "Case Number",
          "qcAllocation.yetToAssignList.table.header.name": "Name",
          "qcAllocation.yetToAssignList.table.header.caseType": "Case Type",
          "qcAllocation.yetToAssignList.table.header.tat": "TAT",
          "qcAllocation.yetToAssignList.table.header.caseStatusName": "Case Status",
          "qcAllocation.yetToAssignList.table.header.assignTo": "Assign To",
          "qcAllocation.yetToAssignList.table.header.completion": "Completion",
          "qcAllocation.yetToAssignList.table.header.readyForQc": "Ready For Qc",
          "qcAllocation.yetToAssignList.filters.max.label": "Max",
          "qcAllocation.yetToAssignList.filters.max.showQcReady": "Show Ready for QC",
          "qcAllocation.assignedCases.heading": "Assigned List",
          "qcAllocation.assignedCases.table.header.sno": "S.No.",
          "qcAllocation.assignedCases.table.header.caseNumber": "Case Number",
          "qcAllocation.assignedCases.table.header.name": "Name",
          "qcAllocation.assignedCases.table.header.caseType": "Case Type",
          "qcAllocation.assignedCases.table.header.tat": "TAT",
          "qcAllocation.assignedCases.table.header.qcStatus": "QC Status",
          "qcAllocation.assignedCases.table.header.assignTo": "Assigned To",
          "qcAllocation.assignedCases.filters.max.label": "Max",
          "qcAllocation.assignedCases.filters.assignee.label": "Assignee Filter",
          "general": "General",
          "generalLayout.pageSelect.placeholder": "Select Page",
          "countryCrud.input.countrySearch.placeholder": "Country Name",
          "countryCrud.btn.addCountry.text": 'Add Country',
          "countryCrud.label.countryName.text": "Country Name",
          "stateCrud.label.stateName.text": "State Name",
          "stateCrud.input.countrySearch.placeholder": "State Name",
          "stateCrud.btn.addState.text": "Add State",
          "stateCrud.select.countrySelect.placeholder": "Select Country",
          "stateCrud.input.stateSearch.placeholder": "Search State",
          "cityCrud.label.cityName.text": "City Name",
          "cityCrud.select.stateSelect.placeholder": "Select State",
          "cityCrud.input.citySearch.placeholder": "City Name",
          "cityCrud.btn.addCity.text": "Add City",
          "employmentAssignment.table.header.sno": "S.No.",
          "employmentAssignment.table.header.caseNumber": "Case Number",
          "employmentAssignment.table.header.name": "Name",
          "employmentAssignment.table.header.caseType": "Case Type",
          "employmentAssignment.table.header.tat": "TAT",
          "employmentAssignment.table.header.status": "Status",
          "employmentAssignment.table.header.assignedTo": "Assigned To",
          "employmentAssignment.filters.client.placeholder": "Select Client",
          "employmentAssignment.filters.search.placeholder": "Search",
          "employmentAssignment.filters.assignee.placeholder": "Select Assignee",
          "employmentAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "residenceAssignment.table.header.sno": "S.No.",
          "residenceAssignment.table.header.caseNumber": "Case Number",
          "residenceAssignment.table.header.name": "Name",
          "residenceAssignment.table.header.bgvId": "Bgv Id",
          "residenceAssignment.table.header.caseReceivedDate": "Case Received Date",
          "residenceAssignment.table.header.tat": "TAT",
          "residenceAssignment.table.header.numberOfChecks": "No. of checks",
          "residenceAssignment.filters.search.placeholder": "Search",
          "residenceAssignment.filters.assignTo.placeholder": "Assing to",
          "residenceAssignment.table.header.assignedTo": "Assigned To",
          "residenceAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "educationAssignment.table.header.sno": "S.No.",
          "educationAssignment.table.header.caseNumber": "Case Number",
          "educationAssignment.table.header.name": "Name",
          "educationAssignment.table.header.caseType": "Case Type",
          "educationAssignment.table.header.tat": "TAT",
          "educationAssignment.table.header.status": "Status",
          "educationAssignment.table.header.assignedTo": "Assigned To",
          "educationAssignment.filters.client.placeholder": "Select Client",
          "educationAssignment.filters.search.placeholder": "Search",
          "educationAssignment.filters.assignee.placeholder": "Select Assignee",
          "educationAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "operations.education.listview.table.header.sno": "S. No.",
          "operations.education.listview.table.header.caseNumber": "Case Number",
          "operations.education.listview.table.header.name": "Candidate Name",
          "operations.education.listview.table.header.client": "Client",
          "operations.education.listview.table.header.institutionName": "Institution Name",
          "operations.education.listview.table.header.tat": "TAT(days)",
          "operations.education.listview.table.header.status": "Status",
          "operations.education.listview.table.header.assignedTo": "Assigned To",
          "operations.education.listview.table.header.action": "Action",
          "operations.employment.listview.table.header.sno": "S No",
          "operations.employment.listview.table.header.caseNumber": "Case Number",
          "operations.employment.listview.table.header.name": "Name",
          "operations.employment.listview.table.header.clientName": "Client Name",
          "operations.employment.listview.table.header.status": "Status",
          "operations.employment.listview.table.header.companyName": "Company Name",
          "operations.employment.listview.table.header.dueDate": "Due Date",
          "operations.employment.listview.table.header.tat": "TAT(days)",
          "operations.employment.listview.table.header.assignedTo": "Assigned To",
          "operations.employment.listview.table.header.action": "Action",
          "beyondInternalTat": "Beyond Internal TAT",
          "showCompleted": "Show Completed",
          "operations.resouceTable.header.name": "Name",
          "operations.resouceTable.header.totalCount": "Total Count",
          "operations.resouceTable.header.wipCount": "WIP Count",
          "operations.resouceTable.header.withinTatCount": "Within TAT Count",
          "operations.resouceTable.header.beyondTatCount": "Beyond TAT Count",
          "operations.resouceTable.header.beyondTatPercentage": "Beyond TAT %",
          "operations.resouceTable.header.completedCount": "Completed Count",
          "operations.residence.filter.clientFilter.placeholder": "Client Filter",
          "operations.residence.filter.assigneeFilter.placeholder": "Assignee Filter",
          "operations.residence.filter.showCompleted.label": "Show Completed",
          "operations.residence.filter.beyondInternalTat.label": "Beyond Internal TAT",
          "operations.residence.filter.includeReportShared.label": "Include Report Shared",
          "courtAssignment.table.header.sno": "S.No.",
          "courtAssignment.table.header.caseNumber": "Case Number",
          "courtAssignment.table.header.name": "Name",
          "courtAssignment.table.header.bgvId": "Bgv Id",
          "courtAssignment.table.header.caseReceivedDate": "Case Received Date",
          "courtAssignment.table.header.tat": "TAT",
          "courtAssignment.table.header.numberOfChecks": "No. of checks",
          "courtAssignment.filters.search.placeholder": "Search",
          "courtAssignment.filters.assignTo.placeholder": "Assing to",
          "courtAssignment.table.header.assignedTo": "Assigned To",
          "courtAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "courtAssignment.filters.clientFilter.placeholder": "Client Filter",
          "courtAssignment.filters.assignee.placeholder": "Select Assignee",
          "courtAssignment.table.header.status": "Status",
          "referenceAssignment.table.header.sno": "S.No.",
          "referenceAssignment.table.header.caseNumber": "Case Number",
          "referenceAssignment.table.header.name": "Name",
          "referenceAssignment.table.header.caseType": "Case Type",
          "referenceAssignment.table.header.tat": "TAT",
          "referenceAssignment.table.header.status": "Status",
          "referenceAssignment.table.header.assignedTo": "Assigned To",
          "referenceAssignment.filters.client.placeholder": "Select Client",
          "referenceAssignment.filters.search.placeholder": "Search",
          "referenceAssignment.filters.assignee.placeholder": "Select Assignee",
          "referenceAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "operations.reference.listview.table.header.sno": "S. No.",
          "operations.reference.listview.table.header.caseNumber": "Case Number",
          "operations.reference.listview.table.header.name": "Candidate Name",
          "operations.reference.listview.table.header.client": "Client",
          "operations.reference.listview.table.header.referee": "Referee",
          "operations.reference.listview.table.header.tat": "TAT(days)",
          "operations.reference.listview.table.header.status": "Status",
          "operations.reference.listview.table.header.assignedTo": "Assigned To",
          "operations.reference.listview.table.header.action": "Action",
          "operations.drug.table.header.sno":"S. No.",
          "operations.drug.table.header.caseNumber": "Case Number",
          "operations.drug.table.header.bgvId": "Bgv Id",
          "operations.drug.table.header.name": "Name",
          "operations.drug.table.header.caseReceivedDate": "Case Received Date",
          "operations.drug.table.header.drugPanel": "Panel",
          "operations.drug.table.header.status": "Status",
          "operations.drug.table.header.tat": "TAT",
          "operations.drug.table.header.scheduledDate": "Scheduled Date",
          "operations.drug.table.header.vendorName": "Vendor",
          "drug.appointmentTable.header.sno": "S.No.",
          "drug.appointmentTable.header.createdDate": "Created Date",
          "drug.appointmentTable.header.appointmentDate": "Appointment Date",
          "drug.appointmentTable.header.venue": "Venue",
          "drug.appointmentTable.header.collectionType": "Collection Type",
          "drug.appointmentTable.header.status": "Status",
          "drug.appointmentTable.header.comment": "Comment",
          "operations.identity.listview.table.header.sno": "S. No.",
          "operations.identity.listview.table.header.caseNumber": "Case Number",
          "operations.identity.listview.table.header.name": "Candidate Name",
          "operations.identity.listview.table.header.client": "Client",
          "operations.identity.listview.table.header.tat": "TAT(days)",
          "operations.identity.listview.table.header.status": "Status",
          "operations.identity.listview.table.header.action": "Action",
          "residenceVendorAssignment.table.header.sno": "S.No.",
          "residenceVendorAssignment.table.header.caseNumber": "Case Number",
          "residenceVendorAssignment.table.header.name": "Name",
          "residenceVendorAssignment.table.header.bgvId": "Bgv Id",
          "residenceVendorAssignment.table.header.caseReceivedDate": "Case Received Date",
          "residenceVendorAssignment.table.header.tat": "TAT",
          "residenceVendorAssignment.table.header.fullAddress": "Address",
          "residenceVendorAssignment.table.header.pincode": "Pincode",
          "residenceVendorAssignment.table.header.status": "Status",
          "residenceVendorAssignment.table.header.vendorName": "Vendor",
          "residenceVendorAssignment.table.header.assignedDate": "Assigned Date",
          "residenceVendorAssignment.table.header.action": "Action",
          "residenceVendorAssignment.table.header.assignedTo": "Assigned To",
          "residenceVendorAssignment.filters.search.placeholder": "Search",
          "residenceVendorAssignment.filters.vendor.placeholder": "Select Vendor",
          "residenceVendorAssignment.filters.assignTo.placeholder": "Assign To",
          "residenceVendorAssignment.filters.city.placeholder": "City Filter",
          "residenceVendorAssignment.filters.state.placeholder": "State Filter",
          "cvAssignment.table.header.sno": "S.No.",
          "cvAssignment.table.header.caseNumber": "Case Number",
          "cvAssignment.table.header.name": "Name",
          "cvAssignment.table.header.tat": "TAT",
          "cvAssignment.table.header.status": "Status",
          "cvAssignment.table.header.assignedTo": "Assigned To",
          "cvAssignment.filters.client.placeholder": "Select Client",
          "cvAssignment.filters.search.placeholder": "Search",
          "cvAssignment.filters.assignee.placeholder": "Select Assignee",
          "cvAssignment.filters.assigneeFilter.placeholder": "Assignee Filter",
          "operations.cv.listview.table.header.sno": "S. No.",
          "operations.cv.listview.table.header.caseNumber": "Case Number",
          "operations.cv.listview.table.header.name": "Candidate Name",
          "operations.cv.listview.table.header.client": "Client",
          "operations.cv.listview.table.header.tat": "TAT(days)",
          "operations.cv.listview.table.header.status": "Status",
          "operations.cv.listview.table.header.assignedTo": "Assigned To",
          "operations.cv.listview.table.header.action": "Action",
     }
};
